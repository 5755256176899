<template>
  <v-container class="px-10" fluid>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar v-model="isCreateFail" :content="snackbarFail" type="error"></snack-bar>

    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Organization</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Add Organization
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" t-data="search-panel">
            <search
              :search="search"
              label="Search by Initials, Branch Name"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center"
            t-data="filter-panel"
          >
            <filter-dropdown @apply="handleApplyFilter()"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="organizationsList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="totalOrganizations"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table localize-table"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:item.initial="{item}">
            {{ item.initial }}
          </template>
          <template v-slot:item.name="{item}" style="width: 130px">
            <v-list-item-content
              class="d-inline-block text-truncate"
              style="width: 300px"
            >
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.name_th }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:item.update_date="{item}">{{
            dateFmt(item.update_date)
          }}</template>
          <template v-slot:item.update_by_user="{item}">
            <username-status-text :user="item.update_by_user" />
          </template>
          <template v-slot:item.is_active="{item}">
            <organization-status-chip :status="item.is_active"></organization-status-chip>
          </template>
          <template v-slot:item.action="{item}">
            <v-icon
              v-if="hasPermEditByOrg(item.codename) || hasPermViewByOrg(item.codename)"
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item.codename)"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-else
              t-data="empty-icon"
              class="px-2 action-icon transparent-icon"
            ></v-icon>
          </template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ totalOrganizations }}</b> Search results
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  convertDate,
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent,
  hasPermissionsByOrgs,
  isStaff
} from '../../helper/helper'
import Search from '@/components/Search'
import SnackBar from '@/components/SnackBar'
import OrganizationStatusChip from '@/components/organization/OrganizationStatusChip'
import FilterDropdown from '@/views/organization/FilterDropdown'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    SnackBar,
    Search,
    FilterDropdown,
    OrganizationStatusChip,
    UsernameStatusText
  },
  data() {
    return {
      pageName: 'organization',
      heightOptions: {
        header: 312,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      updateSuccessMsg: 'Organization status have been updated successfully.',
      deleteSuccessMsg: 'Delete Organization has been successfully.',
      breadcrumbs: [
        {
          text: 'App Config',
          exact: true,
          href: '/'
        },
        {
          text: 'Organization',
          disabled: true,
          link: true,
          href: '/'
        }
      ],
      search: '',
      options: {},
      page: 1,
      headers: [
        {
          text: 'Initials',
          align: 'left',
          value: 'initial',
          sortable: true,
          width: 100
        },
        {
          text: 'Branch',
          align: 'left',
          value: 'name',
          sortable: true,
          width: 318
        },
        {
          text: 'Roles',
          align: 'left',
          value: 'permission_count',
          sortable: false,
          width: 112
        },
        {
          text: 'User',
          align: 'left',
          value: 'user_count',
          sortable: false,
          width: 112
        },
        {
          text: 'Updated Date/Time',
          align: 'center',
          value: 'update_date',
          sortable: true,
          width: 212
        },
        {
          text: 'Updated by',
          align: 'left',
          value: 'update_by_user',
          sortable: true,
          width: 212
        },
        {
          text: 'Status',
          align: 'left',
          value: 'is_active',
          sortable: true,
          width: 137
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'action',
          sortable: false,
          width: 92
        }
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100],
      action: {
        status: '',
      },
    }
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('organization', [
      'isLoading',
      'organizationsList',
      'totalOrganizations',
    ])
  },
  async created() {
    await this.fetch()
    this.setOrgInfo()
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    create() {
      this.$router.push({name: 'organization-create'})
    },
    hasPermCreate() {
      return hasPermision('CanAddOrganization')
    },
    hasPermEdit() {
      return hasPermision('CanEditOrganization')
    },
    hasPermDelete() {
      return hasPermision('CanDeleteOrganization')
    },
    hasPermViewByOrg(codename) {
      return isStaff() ? true : hasPermissionsByOrgs('CanViewOrganization', [codename])
    },
    hasPermEditByOrg(codename) {
      return hasPermissionsByOrgs('CanEditOrganization', [codename])
    },
    dateFmt: date => convertDate(date),
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    rowClick(id) {
      this.$router.push({name: 'organization-edit', params: {id}})
    },
    ...mapActions('organization', [
      'fetch',
      'resetList',
      'setFieldSort',
      'setOrgInfo',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch',
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>
