<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>

      <v-list-item class="ml-2 mr-2 mt-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Status</p>
          <template v-for="(item, i) in statusItems">
            <v-checkbox
              color="#9E8819"
              :key="i"
              v-model="statusSelected"
              :label="item.text"
              :value="item.value"
              class="mt-0 filterContent body-regular"
              hide-details
            ></v-checkbox>
          </template>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '@/components/MultipleSelected'
import FilterButton from '@/components/FilterButton'
import {convertSelectedData} from '@/helper/helper'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    statusItems: [
      {
        text: 'Active',
        value: true
      },
      {
        text: 'Inactive',
        value: false
      },
    ],
    statusSelected: [],
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
    appliedStatus: [],
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected,
  },
  computed: {
    isClearFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      const changedStatus = !_.isEqual(this.statusSelected, [])
      if (this.hasOneOrg) return  changedStatus
      return changedBranch || changedStatus
    },
    isChangeFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, this.appliedBranches)
      const changedStatus = !_.isEqual(this.statusSelected, this.appliedStatus)
      if (this.hasOneOrg) return changedStatus
      return changedBranch || changedStatus
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    ...mapState('organization', ['orgList'])
  },
  methods: {
    sumFiltered() {
      const sumBranch = this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumStatus = this.statusSelected.length > 0 ? 1 : 0
      return sumBranch + sumStatus
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBranches = this.orgList.selected
      this.appliedStatus = this.statusSelected
      this.setFilterStatus(this.statusSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.statusSelected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.statusSelected = this.appliedStatus
        this.setFilterStatus(this.statusSelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('organization', ['fetch', 'setFilterStatus'])
  }
}
</script>
