<template>
  <v-chip
    t-data="status-chip"
    :text-color="statusTextColor(status)"
    :color="statusFillColor(status)"
    dark
  >
    {{ statusName(status) }}
  </v-chip>
</template>

<script>
import {organizationStatus, organizationStatusEnum} from '@/constants/organization'

export default {
  name: 'OrganizationStatusChip',
  props: {
    status: {type: Boolean}
  },
  methods: {
    statusTextColor(status) {
      if (status === organizationStatusEnum.ACTIVE) return 'info800Color'
      if (status === organizationStatusEnum.INACTIVE) return 'disableTextColor'
    },
    statusFillColor(status) {
      if (status === organizationStatusEnum.ACTIVE) return 'info200Color'
      if (status === organizationStatusEnum.INACTIVE) return 'failChip'
    },
    statusName(status) {
      return organizationStatus[status]
    }
  }
}
</script>
